
import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import {
  UserService,
  EmployerNoticeService,
  WechatService,
  ReportService,
  PrivacyTermService,
  EmployerGeneralSettingsService,
  OrderService,
  PageService,
  EmployerAuthService,
  FamilyService,
  PackageService,
} from "src/services";
import tabbar from "src/components/tabbar";
import { tools } from "src/utils";
import { Payment_Type } from "src/enums";
import ServiceAgreement from "src/views/user/privacy/service-agreement.vue";
import router from "src/router";
import globalConfig from "../config/index";
// import sensorsUtils from "src/utils/sensorsUtils";
import BannerPage from "src/views/components/banner/page.vue";
import TitlePage from "src/views/components/title/page.vue";
import NavigationPage from "src/views/components/navigation/page.vue";
import ProductPage from "src/views/components/product/page.vue";
import ArticlePage from "src/views/components/article/page.vue";
import InformationSignboardPage from "src/views/components/informationSignboard/page.vue";
import NotifyPage from "src/views/components/notify/page.vue";
import PrefecturePage from "src/views/components/prefecture/page.vue";
import PersonalPage from "src/views/components/personal/page.vue";
import OrderPage from "src/views/components/order/page.vue";
const showRightsCode = globalConfig.showRightsCode;

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    tabbar,
    ServiceAgreement,
    BannerPage,
    TitlePage,
    NavigationPage,
    ProductPage,
    ArticlePage,
    InformationSignboardPage,
    NotifyPage,
    PrefecturePage,
    PersonalPage,
    OrderPage,
  },
})
export default class Home extends Vue {
  /**
   * 是否是oppo
   * @private
   * @returns boolean
   */
  private isOppo: boolean = false;

  /**
   * 套餐id
   * @private
   * @returns boolean
   */
  private packageId: any = null;

  /**
   * oppo消息弹窗
   * @private
   * @returns boolean
   */
  private showOppoNotice: boolean = false;

  /**
   * 授权弹框
   * @private
   * @returns boolean
   */
  protected showOverlay: boolean = false;

  /**
   * 是否同意过最新的隐私条款
   * @private
   * @returns any
   */
  private privacyTermInfo: any = {
    id: null,
    title: "",
    version: "",
    abstract: "",
    mainBody: "",
    privacyTermTime: "",
  };

  /**
   * 授权信息
   * @private
   * @returns any
   */
  private employerAuthInfo: any = {
    authContent: "",
    authTitle: "",
    id: null,
    isEnable: false,
  };

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private async beforeRouteEnter(to, from, next): Promise<void> {
    if (await tools.isWechat()) {
      (wx.miniProgram as any).getEnv((res) => {
        if (res.miniprogram) {
          next();
        } else {
          let appid = localStorage.getItem("appid");

          if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
            location.assign(`/${appid}${to.fullPath}`);
          } else {
            next();
          }
        }
      });
    } else {
      next();
    }
  }

  /**
   * 监听页面的滚动
   * @private
   * @returns number
   */
  private onscroll: boolean = false;

  /**
   * 显示隐私协议
   * @private
   * @returns number
   */
  private showPrivacyDetail: boolean = false;

  /**
   * 不同意-显示
   * @private
   * @returns boolean
   */
  private showDisagree: boolean = false;

  /**
   * 是否显示服务协议及隐私政策更新提示
   * @private
   * @returns boolean
   */
  private showPrivacyPicker: boolean = false;

  /**
   * 隐私同意按钮是否可点击
   * @private
   * @returns boolean
   */
  private agreeBtn: boolean = false;

  /**
   * 修改弹框
   * @private
   * @returns boolean
   */
  protected showNotice: boolean = false;

  /**
   * 修改中医弹框
   * @private
   * @returns boolean
   */
  protected showTCM: boolean = false;

  /**
   * 个人信息
   * @private
   * @returns boolean
   */
  public personalUser: Object = {};

  /**
   * 是否展示检前须知
   * @private
   * @returns boolean
   */
  public isShowNotice: Boolean = false;

  /**
   * 已读通告
   * @private
   * @returns Array<any>
   */
  protected readNoticePopUpList: Array<any> = [];

  protected async created() {
    localStorage.removeItem("isFormAi");
    this.isUpdateMarriageStatus();
    this.getUserDetail();
    // 是否展示检前须知
    this.isShowPhysicalNotice();
    // 是否只有一个套餐
    this.singlePackage();

    if (await tools.isWeatchMini()) {
      // 如果是友邦的就不做处理。
      let aiaCodes = ["T5QUEHMHQD", "ybfa"];
      if (aiaCodes.includes(this.appid)) {
        return;
      }
      if (window.history.length > 1) {
        this.$router.go(-(window.history.length - 1));
      }
    }

    // if (tools.isWechat())
    // {
    //     history.pushState(null, null, document.URL);
    //     window.addEventListener('popstate', function() {
    //         history.pushState(null, null, document.URL);
    //     });
    // }
  }

  // protected async destroyed() {
  //     if (tools.isWechat())
  //     {
  //         setTimeout(() => {
  //             window.removeEventListener("popstate", function() {
  //                 history.pushState(null, null, document.URL);
  //             }, true);
  //         }, 100);
  //     }
  // }

  /**
   * 是否可修改婚姻状态
   * @private
   * @returns void
   */
  public isEditStatus: boolean = true;

  /**
   * 首页数据
   * @private
   * @returns any
   */
  protected homeData: any = {
    noticePopUpList: [],
  };

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    let userInfoStorge = localStorage.getItem("userInfo");

    if (userInfoStorge) {
      return JSON.parse(userInfoStorge);
    }

    return {};
  }

  /**
   * 是否展示检前须知
   * @private
   * @returns Promise<void>
   */
  private async isShowPhysicalNotice(): Promise<void> {
    try {
      let { content: result } = await EmployerNoticeService.instance.isShowPhysicalNotice();
      this.isShowNotice = result.data;
      this.$store.dispatch("setIsShowNotice", result.data);
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 是否展示套餐列表
   * @private
   * @returns Promise<void>
   */
  private async singlePackage(): Promise<void> {
    try {
      let { content: result } = await PackageService.instance.singlePackage(this.userInfo.userId);
      this.packageId = result.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取用户详情
   * @private
   * @returns Promise<void>
   */
  private async getUserDetail(): Promise<void> {
    try {
      let { content: result } = await FamilyService.instance.getFamilyDetail(this.userInfo.userId);
      this.personalUser = result.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取企业信息
   * @private
   * @returns any
   */
  private get orgInfo(): any {
    let orgInfoStorge = localStorage.getItem("orgInfo");
    if (orgInfoStorge) {
      return JSON.parse(orgInfoStorge);
    }
    return {};
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get showRights(): any {
    return showRightsCode.includes(this.appid);
  }

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private get appid(): string {
    return localStorage.getItem("appid") || "";
  }

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("$route", { deep: true, immediate: true })
  protected async updateOpen(to: any) {
    localStorage.removeItem("employerAuthId");
    this.getEmployerAuth();
    if (await tools.isWechat()) {
      let openid = localStorage.getItem("openid");
      if (!this.$route.query.openid && !openid) {
        this.wechatOauth();
      } else if (this.$route.query.openid) {
        localStorage.setItem("openid", this.$route.query.openid as string);
        // sensorsUtils.bind("identity_OpenId", this.$route.query.openid);
      }
    }

    if (globalConfig.employerConfig[this.appid] && globalConfig.employerConfig[this.appid].diyHome_t1) {
      // 如果是oppo,则不使用自定义首页
      this.isOppo = true;
    } else {
      this.getPageData();
      this.isOppo = false;
    }
    this.getNoticePopUpList();
    this.getGeneralSettings();
    this.checkIsPerfect();
  }

  /**
   * 是否可以修改婚姻状态
   * @private
   * @returns boolean
   */
  private async isUpdateMarriageStatus() {
    try {
      let { content: result } = await EmployerNoticeService.instance.isUpdateMarriageStatus();
      this.isEditStatus = result.data;
      this.$store.dispatch("setIsEditStatus", result.data);
    } catch (error) {}
  }

  /**
   * 跳转订单列表
   * @private
   * @returns void
   */
  private onOrderDetail(isPhysical: string): any {
    this.$router.push({ name: "order-list", query: { isPhysical } });
  }

  /**
   * 微信授权
   * @private
   * @returns void
   */
  private async wechatOauth(): Promise<void> {
    let url = location.href;

    try {
      let { content: result } = await UserService.instance.wechatOauth(url);
      if (result && result.data) {
        location.href = result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 获取授权信息
   * @private
   * @returns void
   */
  private async getEmployerAuth(): Promise<void> {
    try {
      let { content: result } = await EmployerAuthService.instance.getEmployerAuth();
      if (result && result.data) {
        this.employerAuthInfo = result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 新增授权信息
   * @private
   * @returns void
   */
  private async addEmployerAuth(): Promise<void> {
    try {
      let data = {
        authId: this.employerAuthInfo.id,
        authTitle: this.employerAuthInfo.authTitle,
        authStatus: true,
      };
      let { content: result } = await EmployerAuthService.instance.addEmployerAuth(data);
      if (result && result.data) {
        localStorage.setItem("employerAuthId", result.data.id);
        if (this.isSpecial) {
          // 专场点击
          this.$router.push({ name: "physical-package-oppo", query: { type: "1" } });
        } else {
          this.$router.push({ name: "physical-package", query: { type: "1" } });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 获取通知弹框列表
   * @private
   * @returns void
   */
  private async getNoticePopUpList(): Promise<void> {
    try {
      this.showNotice = false;
      let { content: result } = await EmployerNoticeService.instance.getNoticePopUpList();
      if (result && result.data) {
        this.homeData.noticePopUpList = result.data.filter((res) => res.isRead == false);
        this.homeData.noticePopUpList = this.homeData.noticePopUpList.filter((res) => this.readNoticePopUpList.indexOf(res.id) < 0);
        for (const i in this.homeData.noticePopUpList) {
          let title = this.homeData.noticePopUpList[i].title;
          if (title.length > 14) {
            this.homeData.noticePopUpList[i].title = title.substring(0, 14);
          }
        }
        if (this.homeData.noticePopUpList.length > 0) {
          this.showNotice = true;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 先判断是否有未同意的协议
   * @private
   * @returns void
   */
  private async checkPrivacyTerm(): Promise<void> {
    try {
      // let { content: result } =
      //     await PrivacyTermService.instance.checkPrivacyTerm();

      // if (result && result.data) {
      //     this.privacyTermInfo = Object.assign({}, result.data.privacyTermInfo);
      //     if (!result.data.isAgree) {
      //         this.onReport();
      //     }
      //     else {
      //         router.push({ name: "reportAppointmentList" });
      //     }
      // }

      // 确认客户是否已经验证过是本人
      let { content: result } = await ReportService.instance.checkIdentity();
      if (!result.data.checked) {
        router.push({ name: "identityVerify" });
      } else {
        router.push({ name: "reportAppointmentList" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 跳转个人版，获取Token的Key
   * @private
   * @returns void
   */
  private async setPersonalBtn(reportContent): Promise<void> {
    try {
      let { content: result } = await UserService.instance.jump2Personal();

      if (result && result.data) {
        // 个人版小程序
        let personalParams = {
          appid: "gh_387fc8fa08dd",
          eleId: "personal", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/health-records/index`, // 跳转小程序的页面路径
          content: reportContent, // 自定义的html内容
        };

        this.wx_launch(personalParams);

        // 健康档案
        let healthRecord = {
          appid: "gh_387fc8fa08dd",
          eleId: "healthrecord", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/health-records/index`, // 跳转小程序的页面路径
          content: reportContent, // 自定义的html内容
        };

        this.wx_launch(healthRecord);

        // 报告解读 -  拿到最新一条未解读数据
        try {
          let { content: resultData } = await UserService.instance.queryNotSumbitAnalyzeReport();
          let reportParams = {
            appid: "gh_387fc8fa08dd",
            eleId: "report", // 元素id
            url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/list`, // 跳转小程序的页面路径
            content: reportContent, // 自定义的html内容
          };
          if (resultData && resultData.data && resultData.data.id) {
            reportParams.url = `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${resultData.data.id}`;
          }
          this.wx_launch(reportParams);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected async mounted() {
    this.init();
    // 跳转个人版，获取Token的Key
    let reportContent = `
                <button class="test-btn"></button>
                <style>
                    .test-btn{
                        width: 100%;
                        height: 520px;
                        background: transparent;
                        border: none;
                        color:rgba(255,255,255,0)
                    }
                    .test-btn:focus{outline: 0}
                </style>
                `;
    this.setPersonalBtn(reportContent);
    // 线上视频问诊
    let videoParams = {
      appid: "gh_6a9a096e5dba",
      eleId: "videoReport", // 元素id
      url: "/pages/inquiry/graphicConsu/index?tenantCode=raja", // 跳转小程序的页面路径
      content: reportContent, // 自定义的html内容
    };

    this.wx_launch(videoParams);

    let healthyContent = `
                <button class="test-btn"></button>
                <style>
                    .test-btn{
                        width: 100%;
                        height: 520px;
                        background: transparent;
                        border: none;
                        color:rgba(255,255,255,0)
                    }
                    .test-btn:focus{outline: 0}
                </style>
                `;
    // 线上视频问诊
    let healthyParams = {
      appid: "gh_6a9a096e5dba",
      eleId: "healthy", // 元素id
      url: "/pages/inquiry/graphicConsu/index?tenantCode=raja", // 跳转小程序的页面路径
      content: healthyContent, // 自定义的html内容
    };

    this.wx_launch(healthyParams);

    // // 健康档案
    let TCMParams = {
      appid: "gh_6a9a096e5dba",
      eleId: "TCM", // 元素id
      url: "/pages/inquiry/graphicConsu/index?tenantCode=raja", // 跳转小程序的页面路径
      content: reportContent, // 自定义的html内容
    };

    this.wx_launch(TCMParams);

    // 挂载的时候添加滚动事件
    window.addEventListener("touchmove", this.handleScroll, true);
    window.addEventListener("touchend", this.handleScrollEnd, true);
  }

  //滑动隐藏条件
  protected handleScroll() {
    this.onscroll = true;
  }

  protected handleScrollEnd() {
    this.onscroll = false;
  }

  /**
   * 中医服务
   * @private
   * @returns void
   */
  protected onTCMGoods(): void {
    this.$router.push({
      name: "health-product-list",
      query: { productType: "4", type: "12" },
    });
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result && result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 已读
   * @private
   * @returns void
   */
  private async readNotice(id: any, noticeFrequency: any): Promise<void> {
    if (noticeFrequency == 1) {
      // 每次打开
      this.readNoticePopUpList.push(id);
    }
    try {
      await EmployerNoticeService.instance.noticeRead({ noticeIds: [id] });
      this.getNoticePopUpList();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 跳转外链
   * @private
   * @returns void
   */
  private onActionPage(linkAddress: string): void {
    if (linkAddress) {
      window.location.href = linkAddress;
    }
  }

  /**
   * 跳转路由
   * @private
   * @returns void
   */
  private onActionTo(name: string): void {
    router.push({ name: name });
  }

  /**
   * 体检报告
   * @private
   * @returns void
   */
  private onReport(): void {
    this.showPrivacyPicker = true;
    this.agreeBtn = false;
    setTimeout(() => {
      this.onChangeScroll();
    }, 100);
  }

  /**
   * 监听滚动条
   * @private
   * @returns void
   */
  private onChangeScroll(): void {
    //获取节点
    var scrollDiv = document.getElementById("privacy-nr");
    if (scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight) {
      this.agreeBtn = true;
    }
    //绑定事件
    scrollDiv.addEventListener("scroll", () => {
      if (scrollDiv.scrollTop + scrollDiv.clientHeight + 20 >= scrollDiv.scrollHeight) {
        this.agreeBtn = true;
      }
    });
  }

  /**
   * 同意
   * @private
   * @returns void
   */
  private async onAgree(): Promise<void> {
    if (!this.agreeBtn) {
      return;
    }
    try {
      await PrivacyTermService.instance.agreePrivacyTerm(this.privacyTermInfo.id);
      router.push({ name: "reportAppointmentList" });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 不同意
   * @private
   * @returns void
   */
  private onDisAgree(): void {
    if (!this.agreeBtn) {
      return;
    }
    this.showDisagree = true;
  }

  /**
   * 服务协议及隐私政策跳转
   * @private
   * @returns void
   */
  private onActionPrivacy(): void {
    this.showPrivacyDetail = true;
    // router.push({name: "privacy-detail", query:{id: this.privacyTermInfo.id}})
  }

  /**
   * 关闭隐私协议
   * @private
   * @returns void
   */
  private onClosePrivacy(): void {
    this.showPrivacyDetail = false;
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns any
   */
  private get generalSettings(): any {
    let generalSettingsValue = localStorage.getItem("generalSettings");
    if (generalSettingsValue) {
      return JSON.parse(generalSettingsValue);
    }
    return [];
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns void
   */
  private async getGeneralSettings(): Promise<void> {
    try {
      let { content: result } = await EmployerGeneralSettingsService.instance.employerGeneralSettingsList();
      if (result && result.data) {
        this.$store.dispatch("setGeneralSettings", result.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 体检预约验证
   * @private
   * @returns void
   */
  private async getAppointmentverification(type: string): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.appointmentverification();
      let data = this.generalSettings.filter((res) => res.code == "A2005");

      if (data.length > 0 && data[0].isOpen && this.employerAuthInfo.isEnable) {
        this.isSpecial = false;
        this.showOverlay = true;
      } else {
        this.$router.push({ name: "physical-package", query: { type: type } });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 查询个人信息是否完善
   * @private
   * @returns void
   */
  private async checkIsPerfect(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.checkIsPerfect();
      if (result && result.data) {
        if (!result.data.isPerfect) {
          Dialog.confirm({
            message: "您的个人信息未完善，是否前去完善",
            confirmButtonText: "立即完善",
            cancelButtonText: "稍后完善",
            showCancelButton: false,
          })
            .then(() => {
              this.$router.push({
                name: "perfect-user",
                query: { userId: this.userInfo.userId },
              });
            })
            .catch(() => {});
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 是否显示配置
   * @protected
   * @returns boolean
   */
  protected showStting(code: string): boolean {
    // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
    // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
    // A2001  -- 开启后，同一合同下一人只能预约一次体检，家属不受此规则影响。
    let data = this.generalSettings.filter((res) => res.code == code);
    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return true;
    }
  }

  // 是否显示个人版
  protected get showPersonal(): boolean {
    // A1004  -- 是否显示个人版入口
    let data = this.generalSettings.filter((res) => res.code == "A1004");

    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return false;
    }
  }

  /**
   * 菜单列表
   * @protected
   */
  private menuList: Array<any> = [
    {
      componentType: 1,
      icon: "src/assets/icons/banner-icon.png",
      name: "轮播图",
      pageComponent: "BannerPage",
      configComponent: "BannerConfig",
    },
    {
      componentType: 2,
      icon: "src/assets/icons/title-icon.png",
      name: "标题文本",
      pageComponent: "TitlePage",
      configComponent: "TitleConfig",
    },
    {
      componentType: 3,
      icon: "src/assets/icons/navigation-icon.png",
      name: "导航栏",
      pageComponent: "NavigationPage",
      configComponent: "NavigationConfig",
    },
    {
      componentType: 4,
      icon: "src/assets/icons/product-icon.png",
      name: "产品",
      pageComponent: "ProductPage",
      configComponent: "ProductConfig",
    },
    {
      componentType: 5,
      icon: "src/assets/icons/article-icon.png",
      name: "文章",
      pageComponent: "ArticlePage",
      configComponent: "ArticleConfig",
    },
    {
      componentType: 6,
      icon: "/static/icons/notify-icon.png",
      name: "通知",
      pageComponent: "NotifyPage",
      configComponent: "NotifyConfig",
    },
    {
      componentType: 7,
      icon: "/static/icons/informationSignboard-icon.png",
      name: "信息看板",
      pageComponent: "InformationSignboardPage",
      configComponent: "InformationSignboardConfig",
    },
    {
      componentType: 8,
      icon: "/static/icons/prefecture-icon.png",
      name: "专区",
      pageComponent: "PrefecturePage",
      configComponent: "PrefectureConfig",
    },
    {
      componentType: 9,
      icon: "/static/icons/person-icon.png",
      name: "个人信息",
      pageComponent: "PersonalPage",
      configComponent: "PersonalConfig",
    },
    {
      componentType: 10,
      icon: "/static/icons/order-icon.png",
      name: "订单",
      pageComponent: "OrderPage",
      configComponent: "OrderConfig",
    },
  ];

  /**
   * 首页自定义数据
   * @private
   * @returns any
   */
  protected pageData: any = {
    properties: "",
    pageType: null,
    name: "",
    id: null,
    enable: false,
    employerId: null,
    components: [],
  };

  /**
   * 页面样式
   * @private
   * @returns any
   */
  protected pageStyle: any = {};

  /**
   * 获取首页配置信息
   * @private
   * @returns void
   */
  private async getPageData(): Promise<void> {
    try {
      let { content: result } = await PageService.instance.getPage({ pageType: 1 });
      if (result.data) {
        this.pageData = result.data;
        let components = []; // 转译
        result.data.components.forEach((element) => {
          let json = {
            componentType: element.componentType,
            properties: element.properties,
            icon: "",
            name: "",
            pageComponent: "",
            configComponent: "",
          };
          let temObj = this.menuList.filter((res) => res.componentType == element.componentType)[0];
          json.icon = temObj.icon;
          json.name = temObj.name;
          json.pageComponent = temObj.pageComponent;
          json.configComponent = temObj.configComponent;
          components.push(json);
        });
        this.pageData.components = components;
        this.pageData.employerId = result.data.employerId;
        this.pageData.enable = result.data.enable;
        this.pageData.id = result.data.id;
        this.pageData.name = result.data.name;
        this.pageData.pageType = result.data.pageType;
        this.pageData.properties = result.data.properties;
        this.pageStyle = { "background-color": (result.data.properties && result.data.properties.bgColor) || "" };
        document.title = result.data.properties.navigateTitle || "首页";
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 自定义组件获取的消息
   * @private
   * @returns void
   */
  private diyMsg(data): void {
    if (data.type == 404) {
      // 体检报告
      this.checkPrivacyTerm();
    }
  }

  /**
   * 跳转体检套餐
   * @private
   * @type 体检类型
   * @returns void
   */
  private onPhysicalPackage(type: string): any {
    if (type == "1") {
      this.getAppointmentverification(type);
      // // 是否只能预约一次
      // if (this.showStting("A2001"))
      // {
      //     this.getAppointmentverification(type);
      // }
      // else
      // {
      //    let data = this.generalSettings.filter((res) => res.code == "A2005");
      //     if (data.length > 0 && data[0].isOpen && this.employerAuthInfo.isEnable)
      //     {
      //         this.showOverlay = true;
      //     }
      //     else
      //     {
      //         this.$router.push({ name: "physical-package", query: { type: type } });
      //     }
      // }
    } else {
      this.$router.push({ name: "physical-package", query: { type: type } });
    }
  }

  /**
   * 跳转体检套餐 --  长安专场
   * @private
   * @type 体检类型
   * @returns void
   */
  private onPhysicalPackageSpecial(type: string): any {
    if (type == "1") {
      this.getAppointmentverificationSpecial(type);
      // // 是否只能预约一次
      // if (this.showStting("A2001"))
      // {
      //     this.getAppointmentverification(type);
      // }
      // else
      // {
      //    let data = this.generalSettings.filter((res) => res.code == "A2005");
      //     if (data.length > 0 && data[0].isOpen && this.employerAuthInfo.isEnable)
      //     {
      //         this.showOverlay = true;
      //     }
      //     else
      //     {
      //         this.$router.push({ name: "physical-package", query: { type: type } });
      //     }
      // }
    } else {
      this.$router.push({ name: "physical-package-oppo", query: { type: type } });
    }
  }

  // 是否是专场点击
  private isSpecial: boolean = false;

  /**
   * 体检预约验证
   * @private
   * @returns void
   */
  private async getAppointmentverificationSpecial(type: string): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.appointmentverification();
      let data = this.generalSettings.filter((res) => res.code == "A2005");

      if (data.length > 0 && data[0].isOpen && this.employerAuthInfo.isEnable) {
        this.isSpecial = true;
        this.showOverlay = true;
      } else {
        this.$router.push({ name: "physical-package-oppo", query: { type: type } });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 不同意协议
   * @private
   * @returns void
   */
  private onNoEmployerAuth(): void {
    if (globalConfig.employerConfig[this.appid] && globalConfig.employerConfig[this.appid].noAuth) {
      this.addEmployerAuth();
    } else {
      this.showOverlay = false;
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private async beforeCreate(): Promise<void> {
    try {
      let toPage = this.$route.query.toPage as string;
      if (this.$route.query.toPage && !localStorage.getItem("toPage")) {
        localStorage.setItem("toPage", toPage);
        this.$router.push(JSON.parse(atob(toPage)));
      }
    } catch {}
  }
}
